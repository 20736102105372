(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name entitats.newEntitat.controller:NewEntitatCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.home.entitats.newEntitat')
      .controller('NewEntitatCtrl', NewEntitatCtrl);

  function NewEntitatCtrl($state, $mdToast, $filter, TownhallEntities, townhalls, towns, entityTypes) {
    var vm = this;
    vm.ctrlName = 'NewEntitatCtrl';
    vm.entitat = {
      townHall: {}
    };

    vm.towns = towns;
    vm.townHalls = townhalls;
    vm.entityTypes = entityTypes;
    if(vm.townHalls.length < 2){
      vm.onyOneTownHall = true;
      if (vm.townHalls.length === 1) {
        vm.entitat.townHall = vm.townHalls[0];
      }
    }

    vm.addEntitat = function () {
      TownhallEntities.save({id: vm.entitat.townHall.id}, vm.entitat, function () {
        $state.go('home.entitats', {}, {reload: true});
        $mdToast.show(
            $mdToast.simple()
                .textContent($filter('translate')('ENTITY_SUCCESSFUL_CREATE'))
                .position('bottom left')
                .hideDelay(3000)
        );
      }, function (error) {
        var errorMessage;
        if (error.status === 409) {
          errorMessage = 'ALREADY_EXISTING_ENTITY';
        } else {
          errorMessage = 'SERVER_ERROR';
        }
        $mdToast.show(
            $mdToast.simple()
                .textContent($filter('translate')(errorMessage))
                .position('bottom left')
                .hideDelay(3000)
        );
      });
    };
  }
}());
